import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  input,
  Input,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@ea/components';
import { IResponseAddress, TRequestAddressData } from '@ea/models';
import { LocalStorageService, ToastService } from '@ea/services';
import { of, Subject } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { AddressService } from 'src/app/services/shared/address/address.service';
import { AutoCompleteService } from 'src/app/services/shared/auto-complete/auto-complete.service';
import {
  CountryISO,
  NgxIntlTelInputModule,
  PhoneNumberFormat,
  SearchCountryField,
} from '@justin-s/ngx-intl-tel-input';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'ea-address-editor',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    MatIcon,
  ],
  templateUrl: './address-editor.component.html',
  styleUrls: ['./address-editor.component.scss'],
})
export class AddressEditorComponent extends BaseComponent {
  newAddressForm!: FormGroup;
  customId!: string;
  @Input() firstName?: any = '';
  @Input() lastName?: string;
  @Input() phone = '';
  @Input() fromCheckoutPage?: boolean;
  @Input() fromCartPage?: boolean = false;
  @Input() isCreate?: boolean = false;
  @Input() editAddressId? : any;
    @Output() addressUpdated = new EventEmitter();
    @Output() addressCreated = new EventEmitter();


  addressSuggestions: any[] = [];
  triggerAddressSuggestions = true;
  addressResults: any = [];
  pageTitle = 'Add New Address';
  @Input() address?: any;
  @Input() isBilling?: boolean;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  selectedCountry = CountryISO.UnitedStates;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];

  // Declare a Subject to track component destruction
  private destroy$ = new Subject<void>();

  addressId: any;
  isEdit = false;
  isBillingAddress: boolean = false;
  isShippingAddress: boolean = false;
  editAddressData: any;
  toggleBillingAddress() {
    const currentValue = this.newAddressForm.get('isBillingAddress')!.value;
    this.isBillingAddress = !currentValue;
    this.newAddressForm.get('isBillingAddress')!.setValue(!currentValue);
  }

  toggleShippingAddress() {
    const currentValue = this.newAddressForm.get('isShippingAddress')!.value;
    this.isShippingAddress = !currentValue;

    this.newAddressForm.get('isShippingAddress')!.setValue(!currentValue);
  }
  @Input() set data(value: TRequestAddressData | null) {
    if (value) {
      this.newAddressForm.setControl(
        'customId',
        this.builder.control(value.customId),
      );
      this.newAddressForm.patchValue(value, { emitEvent: false });
    }
  }
  isBrowser: any;
  constructor(
    private builder: FormBuilder,
    private addressService: AddressService,
    private storage: LocalStorageService,
    private autoCompleteService: AutoCompleteService,
    private router: Router,
    private route: ActivatedRoute,
    private toasterService: ToastService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    super();
    this.isBrowser = isPlatformBrowser(this.platformId);
    if (!this.fromCheckoutPage) {
      this.pageTitle = this.route.snapshot.data['title'];
    }
    let location = window.location.pathname.split('/')[1];
    let countryCode = location == 'us' ? 'USA' : 'GB';
    this.selectedCountry =
      location == 'us' ? CountryISO.UnitedStates : CountryISO.UnitedKingdom;

    this.customId = this.storage.getItem('customId') || '';
    this.newAddressForm = this.builder.group({
      isDefaultAddress: this.builder.control(false, Validators.required),
      isShippingAddress: this.builder.control(false, Validators.required),
      isBillingAddress: this.builder.control(false, Validators.required),
      customId: this.builder.control(''),
      currentDefault: this.builder.control(''),
      addressLineOne: this.builder.control('', Validators.required),
      addressLineTwo: this.builder.control(''),
      addressLineThree: this.builder.control(''),
      addressCity: this.builder.control('', Validators.required),
      addressCounty: this.builder.control('', Validators.required),
      addressPostcode: this.builder.control('', Validators.required),
      addressCountry: this.builder.control(countryCode, Validators.required),
      phone: this.builder.control('', Validators.required),
      additionalAttributes: this.builder.group({
        first: this.builder.control('', Validators.required),
        last: this.builder.control('', Validators.required),
        nickName: this.builder.control(''),
      }),
    });

    this.subscriptions.push(
      this.addressService
        .getAddress(this.customId)
        .subscribe((addresses: IResponseAddress[]) => {
          // Check to see if there are any previous addresses
          if (addresses.length === 0) {
            this.newAddressForm.patchValue({
              isDefaultAddress: true,
            });

            //disabled isDefaultAddress checkbox if there are no previous addresses
            this.newAddressForm.get('isDefaultAddress')?.disable();
          }
        }),
    );

    // set the customId
    this.newAddressForm.patchValue({
      customId: this.customId,
    });

    if (this.newAddressForm.get('addressLineOne')) {
      const addressControl = this.newAddressForm.get('addressLineOne');

      if (addressControl) {
        const subscription = addressControl.valueChanges
          .pipe(
            // wait 500ms after each keystroke before considering the term
            debounceTime(500),
            switchMap((value) => {
              // at least 5 characters
              if (value.length >= 5 && this.triggerAddressSuggestions) {
                const country =
                  this.newAddressForm.get('addressCountry')?.value;

                if (!country) return of();

                const x = this.autoCompleteService
                  .getAutoCompleteSuggestions(value, country)
                  .pipe(
                    tap((response: any) => {
                      this.addressSuggestions =
                        response.result?.suggestions || [];
                    }),
                  );

                return x;
              }

              return of();
            }),
          )
          .subscribe();

        this.subscriptions.push(subscription);
      }
    }
  }

  mapAddress(response: any) {
    this.editAddressData = response;
    this.isShippingAddress = response.additionalAttributes.isShippingAddress;
    this.isBillingAddress = response.additionalAttributes.isBillingAddress;
    this.newAddressForm.patchValue({
      ...response,

      addressLineOne: response.addressLine1,
      addressLineTwo: response.addressLine2,
      addressLineThree: response.addressLine3,
      addressCity: response.city,
      addressCounty: response.county,
      addressPostcode: response.postalCode,
      phone: response.phone.number,
      additionalAttributes: {
        first: response.first,
        last: response.last,
        nickName: response.additionalAttributes.nickName,
      },
      isDefaultAddress: response.isDefault,
      isBillingAddress: response.additionalAttributes.isBillingAddress,
      isShippingAddress: response.additionalAttributes.isShippingAddress,
    });

    this.triggerAddressSuggestions = false;
  }
  ngOnInit() {
    if (!this.address) {
      this.subscriptions.push(
        this.route.params.subscribe((params) => {
          this.addressId = this.editAddressId ?? params['id'];

          if (this.addressId) {
            this.isEdit = true;
            this.addressService
              .getSingleAddress(this.addressId, this.customId)
              .subscribe((response) => {
                this.mapAddress(response);
              });
          }
        }),
      );
    } else {
      this.isEdit = true;
      this.pageTitle = 'Edit address';
      this.mapAddress(this.address);
    }
  }

  selectAddress(globalKey: string) {
    this.subscriptions.push(
      this.autoCompleteService
        .getAutoComplete(globalKey)
        .subscribe((response: any) => {
          const {
            first,
            address_line_1,
            address_line_2,
            address_line_3,
            country,
            locality,
            postal_code,
            region,
          } = response?.result?.address || {};
          this.newAddressForm.patchValue({
            first: first,
            addressLineOne: address_line_1,
            addressLineTwo: address_line_2,
            addressLineThree: address_line_3,
            addressPostcode: postal_code,
            //addressCountry: country,
            addressCity: locality,
            addressCounty: region,
          });
          this.addressSuggestions = [];
          // after user selects suggestion, stop making api calls for suggestions
          this.triggerAddressSuggestions = false;
        }),
    );
  }

  createAddress() {
    this.toggleDisable(true);
    const data: TRequestAddressData = {
      ...this.newAddressForm.value,
      first: this.newAddressForm.value.additionalAttributes.first,
      last: this.newAddressForm.value.additionalAttributes.last,
      nickName: this.newAddressForm.value.additionalAttributes.nickName,
      isDefaultAddress: this.newAddressForm.get('isDefaultAddress')?.value,
      phone: this.newAddressForm.value.phone.e164Number
        ? this.newAddressForm.value.phone.e164Number
        : this.newAddressForm.value.phone,
    };

    const customId = this.storage.getItem('customId');

    if (customId) {
      this.subscriptions.push(
        this.addressService.getAddress(customId).subscribe((response) => {
          this.addressResults = response;

          // check if there are existing addresses
          if (this.addressResults.length === 0) {
            this.newAddressForm.patchValue({
              isDefaultAddress: true,
            });

            this.addressResults.find((address: any) => address.isDefault);
          } else {
            this.newAddressForm.patchValue({
              isDefaultAddress: false,
            });
          }
        }),
      );
    }

    if (this.isEdit && !this.isCreate) {
      const address = {
        ...data,
      };
      this.subscriptions.push(
        this.addressService
          .updateAddress({
            addressId: this.editAddressData.addressId,
            isDefaultAddress: address.isDefaultAddress,
            customId: customId,
            isShippingAddress: address.isShippingAddress,
            isBillingAddress: address.isBillingAddress,
            currentDefault: 'string',
            type: 'string',
            addressLineOne: address.addressLineOne,
            addressLineTwo: address.addressLineTwo,
            addressLineThree: address.addressLineThree,
            addressCity: address.addressCity,
            addressCounty: address.addressCounty,
            addressPostcode: address.addressPostcode,
            addressCountry: address.addressCountry,
            phone: address.phone,
            first: this.newAddressForm.value.additionalAttributes.first,
            last: this.newAddressForm.value.additionalAttributes.last,
            nickName: this.newAddressForm.value.additionalAttributes.nickName,
          })
          .subscribe({
            next: (result: any) => {
              if (!this.address) {
                this.toggleDisable(false);

                this.addressUpdated.emit(true)
              } else {
                this.getBack(result);
              }
            },
            error: () => {
              this.toggleDisable(false);
              this.toasterService.show('Error happened!', 'error');
            },
          }),
      );
    } else {
      if (this.isCreate) {
        data.isBillingAddress = this.isCreate;
      }
      this.subscriptions.push(
        this.addressService.createAddress(data).subscribe({
          next: (response) => {
            if (!this.fromCheckoutPage && !this.isCreate) {
              this.addressCreated.emit(true);            } else {
              if (this.isBrowser && !this.isCreate) {
                this.router.navigate(['checkout']).then(() => {
                  window.location.reload();
                });
              }
              if (this.isBrowser && this.isCreate) {
                this.router
                  .navigate(['payment'], {
                    queryParams: { newBullingAddress: response.addressId },
                  })
                  .then(() => {
                    window.location.reload();
                  });
              }
            }
          },
          error: () => {
            this.toggleDisable(false);
                     this.addressCreated.emit(true);

          },
        }),
      );
    }
  }
  @Output() dispose = new EventEmitter<any>();
  getBack(result: any) {
    this.dispose.emit(result);
  }
}
