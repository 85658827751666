<section>
  <div>
    <div
      class="row justify-content-center"
      [ngClass]="fromCartPage ? ' mb-2' : 'mb-5'"
    >
      <div class="col-lg-12">
        <div class="">
          <form
            [formGroup]="newAddressForm"
            [ngClass]="
              fromCartPage
                ? 'ea-form bg-theme-surface mb-2 without-border'
                : 'ea-form bg-theme-surface mb-5'
            "
          >
            <div class="">
              <legend
                *ngIf="!fromCartPage"
                class="justify-content-between row p-0"
              >
                <h1 class="mt-4 mb-3 font-bold col-8">
                  @if(isEdit){ Edit Address } @else{ Add a new address }
                </h1>
                <!--                  <div class="col-4 mt-4 text-right p-0" *ngIf="address || fromCheckoutPage">-->
                <div class="col-4 mt-4 text-right p-0">
                  <button
                    (click)="getBack(null)"
                    class="remove-btn"
                    style="font-size: 15px"
                  >
                    <u> <mat-icon>close</mat-icon> Cancel</u>
                  </button>
                </div>
              </legend>

              <div formGroupName="additionalAttributes" class="mt-4">
                <div class="row">
                  <div
                    [ngClass]="
                      !(this.address || fromCheckoutPage || fromCartPage)
                        ? 'col-12 col-sm-6'
                        : ''
                    "
                  >
                    <label for="nickname-input" class="ea-label form-label"
                      >Nickname</label
                    >
                    <input
                      type="text"
                      class="ea-input w-100 focus-ring"
                      id="nickname-input"
                      formControlName="nickName"
                      name="nickname"
                      placeholder="Nickname"
                      autocomplete="nickname"
                      alt="nickname input"
                    />
                  </div>
                  <div
                    class="col-6 row mt-4 justify-content-center d-none d-sm-flex"
                    *ngIf="!(this.address || fromCheckoutPage || fromCartPage)"
                  >
                    <div class="col-4">
                      <span
                        >Default <br />
                        Billing</span
                      >
                      <br />
                      <label>
                        <input
                          class="form-radio-input mr-2"
                          type="radio"
                          name="billing"
                          formControlName="isBillingAddress"
                          style="width: 30px; height: 30px"
                          formControlName="isBillingAddress"
                          [checked]="isBillingAddress"
                          (click)="toggleBillingAddress()"
                        />
                      </label>
                    </div>
                    <div class="col-4">
                      <span
                        >Default<br />
                        Delivery</span
                      >
                      <br />
                      <label class="">
                        <input
                          class="form-radio-input mr-2"
                          type="radio"
                          name="shipping"
                          formControlName="isShippingAddress"
                          style="width: 30px; height: 30px"
                          [checked]="isShippingAddress"
                          (click)="toggleShippingAddress()"
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="!fromCartPage">
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <label
                      for="firstname-input"
                      class="ea-label form-label required"
                      >First name</label
                    >
                    <input
                      *ngIf="firstName"
                      type="text"
                      class="ea-input w-100 focus-ring"
                      id="firstname-input"
                      formControlName="first"
                      [(ngModel)]="firstName"
                      name="firstname"
                      placeholder="First name"
                      autocomplete="firstname"
                      alt="first name input"
                    />
                    <input
                      *ngIf="!firstName"
                      type="text"
                      class="ea-input w-100 focus-ring"
                      id="firstname-input"
                      formControlName="first"
                      name="firstname"
                      placeholder="First name"
                      autocomplete="firstname"
                      alt="first name input"
                    />
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <label
                      for="lastname-input"
                      class="ea-label form-label required"
                      >Last name</label
                    >
                    <input
                      *ngIf="!lastName"
                      type="text"
                      class="ea-input w-100 focus-ring"
                      id="lastname-input"
                      formControlName="last"
                      name="lastname"
                      placeholder="Last name"
                      autocomplete="lastname"
                      alt="last name input"
                    />
                    <input
                      *ngIf="lastName"
                      type="text"
                      class="ea-input w-100 focus-ring"
                      id="lastname-input"
                      formControlName="last"
                      [(ngModel)]="lastName"
                      name="lastname"
                      placeholder="Last name"
                      autocomplete="lastname"
                      alt="last name input"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <label
                    for="address1-input"
                    class="ea-label form-label required"
                  >
                    Address Line 1
                  </label>
                  <input
                    type="text"
                    class="ea-input w-100 focus-ring"
                    id="address1-input"
                    formControlName="addressLineOne"
                    name="addressLine1"
                    placeholder="The first line of your address"
                    autocomplete="address-line1"
                    (input)="triggerAddressSuggestions = true"
                    alt="address Line one input"
                  />
                  <div class="suggestionDropDoen">
                    <ul *ngIf="addressSuggestions.length" class="list-group">
                      <li
                        class="list-group-item"
                        *ngFor="let item of addressSuggestions"
                        (click)="selectAddress(item.global_address_key)"
                      >
                        {{ item.text }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label for="address2-input" class="ea-label form-label"
                  >Address Line 2</label
                >
                <input
                  type="text"
                  class="ea-input w-100 focus-ring"
                  id="address2-input"
                  formControlName="addressLineTwo"
                  name="addressLine2"
                  placeholder="The second line of your address"
                  autocomplete="address-line2"
                  alt="address Line two input"
                />
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label for="address3-input" class="ea-label form-label"
                  >Address Line 3</label
                >
                <input
                  type="text"
                  class="ea-input w-100 focus-ring"
                  id="address3-input"
                  formControlName="addressLineThree"
                  name="addressLine3"
                  placeholder="The Third line of your address"
                  autocomplete="address-line3"
                  alt="address Line three input"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <label for="city-input" class="ea-label form-label required"
                  >City</label
                >
                <input
                  type="text"
                  class="ea-input w-100 focus-ring"
                  id="city-input"
                  formControlName="addressCity"
                  name="city-input"
                  placeholder="Your city of residence"
                  autocomplete="city-input"
                  alt="city input"
                />
              </div>

              <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <label for="county-input" class="ea-label form-label required"
                  >County</label
                >
                <input
                  type="text"
                  class="ea-input w-100 focus-ring"
                  id="county-input"
                  formControlName="addressCounty"
                  name="county-input"
                  placeholder="Your county of residence"
                  autocomplete="county-input"
                  alt="county input"
                />
              </div>

              <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <label for="country-input" class="ea-label form-label required"
                  >Country</label
                >
                <!--                                <input-->
                <!--                                  type="text"-->
                <!--                                  class="ea-input w-100 focus-ring"-->
                <!--                                  id="country-input"-->
                <!--                                  formControlName="addressCountry"-->
                <!--                                  name="country-input"-->
                <!--                                  placeholder="Your country of residence"-->
                <!--                                  autocomplete="country-input"-->
                <!--                                />-->
                <select
                  class="ea-input w-100 focus-ring"
                  id="country-input"
                  formControlName="addressCountry"
                  name="country-input"
                  autocomplete="country-input"
                >
                  <option value="USA">USA</option>
                  <option value="GB">UNITED KINGDOM</option>
                </select>
              </div>
            </div>

            <div [ngClass]="fromCartPage ? '' : 'row'">
              <div
                [ngClass]="
                  fromCartPage
                    ? 'col-12'
                    : 'col-sm-12 col-md-6 col-lg-6 col-xl-6'
                "
              >
                <label
                  for="postal-code-input"
                  class="ea-label form-label required"
                  >Postal Code</label
                >
                <input
                  type="text"
                  class="ea-input w-100 focus-ring"
                  id="postal-code-input"
                  formControlName="addressPostcode"
                  name="postal-code-input"
                  placeholder="Your postal code"
                  autocomplete="postal-code-input"
                  alt="postal code input"
                />
              </div>

              <div
                class="col-sm-12 col-md-6 col-lg-6 col-xl-6"
                *ngIf="!fromCartPage"
              >
                <label for="phone-input" class="ea-label form-label required"
                  >Phone No.</label
                >
                <ngx-intl-tel-input
                  *ngIf="phone"
                  [cssClass]="'custom ea-input w-175 focus-ring'"
                  [preferredCountries]="preferredCountries"
                  [enableAutoCountrySelect]="true"
                  [enablePlaceholder]="true"
                  [searchCountryFlag]="true"
                  [searchCountryField]="[
                    SearchCountryField.Iso2,
                    SearchCountryField.Name
                  ]"
                  [selectFirstCountry]="false"
                  [selectedCountryISO]="selectedCountry"
                  [maxLength]="15"
                  [phoneValidation]="true"
                  [separateDialCode]="separateDialCode"
                  [numberFormat]="PhoneNumberFormat.National"
                  placeholder="Your contact phone number"
                  name="phone-input"
                  formControlName="phone"
                  [(ngModel)]="phone"
                ></ngx-intl-tel-input>
                <ngx-intl-tel-input
                  *ngIf="!phone"
                  [cssClass]="'custom ea-input w-175 focus-ring'"
                  [preferredCountries]="preferredCountries"
                  [enableAutoCountrySelect]="true"
                  [enablePlaceholder]="true"
                  [searchCountryFlag]="true"
                  [searchCountryField]="[
                    SearchCountryField.Iso2,
                    SearchCountryField.Name
                  ]"
                  [selectFirstCountry]="false"
                  [selectedCountryISO]="selectedCountry"
                  [maxLength]="15"
                  [phoneValidation]="true"
                  [separateDialCode]="separateDialCode"
                  [numberFormat]="PhoneNumberFormat.National"
                  placeholder="Your contact phone number"
                  name="phone-input"
                  formControlName="phone"
                ></ngx-intl-tel-input>
              </div>
              <div
                class="col-12 row mt-4 justify-content-center d-flex d-sm-none"
                *ngIf="!(this.address || fromCheckoutPage || fromCartPage)"
              >
                <div class="col-12 mb-2 d-flex align-items-center">
                  <input
                    class="form-radio-input mr-2"
                    type="radio"
                    name="billing"
                    formControlName="isBillingAddress"
                    id="isBillingAddress"
                    style="width: 30px; height: 30px"
                    [checked]="isBillingAddress"
                    (click)="toggleBillingAddress()"
                  />
                  <label for="isBillingAddress" class="p-0"> Default Billing </label>
                </div>
                <div class="col-12 mb-2 d-flex align-items-center">
                  <input
                    class="form-radio-input mr-2"
                    type="radio"
                    name="shipping"
                    formControlName="isShippingAddress"
                    id="isShippingAddress"
                    style="width: 30px; height: 30px"
                    [checked]="isShippingAddress"
                    (click)="toggleShippingAddress()"
                  />
                  <label for="isShippingAddress" class="p-0">
                    Default Delivery
                  </label>
                </div>
              </div>
            </div>

            <div
              class="row"
              *ngIf="!address && !fromCheckoutPage && !fromCartPage"
            >
              <div class="col mt-4 mb-5">
                <button
                  (click)="createAddress()"
                  type="submit"
                  title="Save"
                  value="Save"
                  class="ea-button ea-button-primary col-sm-12 col-md-12 col-lg-12 col-xl-12 text-uppercase focus-ring w-100"
                  [disabled]="
                    newAddressForm.invalid || (isButtonsDisabled$ | async)
                  "
                >
                  <strong>Save</strong>
                </button>
              </div>
            </div>

            <div class="text-right" *ngIf="address">
              <div class="col mt-4 mb-5">
                <button
                  (click)="createAddress()"
                  type="submit"
                  title="Save"
                  value="Save"
                  class="ea-button ea-button-primary focus-ring w-30"
                  [disabled]="
                    newAddressForm.invalid || (isButtonsDisabled$ | async)
                  "
                >
                  <span><strong>Save address changes</strong></span>
                </button>
              </div>
            </div>
            <div class="row" *ngIf="!address && fromCheckoutPage">
              <div class="col mt-4 mb-5">
                <button
                  (click)="createAddress()"
                  type="submit"
                  title="Save"
                  value="Save"
                  class="ea-button ea-button-primary focus-ring w-100"
                  [disabled]="
                    newAddressForm.invalid || (isButtonsDisabled$ | async)
                  "
                >
                  <strong>Add new address</strong>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
